.login-field{
    max-width: 600px !important;
}

.button-forgot-password{
    margin-left: 25%;
    --bs-btn-border-color: rgb(212, 212, 212) !important;
    font-size: 0.8rem;
    padding: 5px;
}

.password-title{
    .password-info{
        --bs-btn-bg: #ffffff !important;
        --bs-btn-border-color: #ffffff;
        --bs-btn-hover-bg: #ffffff;
        --bs-btn-focus-shadow-rgb: 247, 97, 122;
        --bs-btn-active-color: #ffffff;
        --bs-btn-active-bg: #ffffff;
        --bs-btn-active-border-color: #ffffff;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
        --bs-btn-disabled-color: #ffffff;
        --bs-btn-disabled-bg: #ffffff;
        --bs-btn-disabled-border-color: #ffffff;
    }
    .btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
        background-color: #ffffff !important;
        border-color: #ffffff !important;
        box-shadow: none;
    }
    .btn{
        padding: 0;
        border: none !important;
        --bs-btn-box-shadow: none !important;
    }
}

.button-signin-login{
    margin-top: 2.5rem;
}

.icon-success{
    font-size: 15rem;
    color: #F54562;
    margin: auto;
}

.text-success{
    font-size: 5rem;
}
.text-checking{
    padding: 0;
    min-width: 225px;
    margin: auto;
    width: 40%;
    font-size: 2rem;
    margin-top: 10%;
    padding: 0 2rem;
    border-radius: 5rem;
    background-color: #eeeeee;
}
.secondary-text-success{
    font-size: 1.5rem;
}
.mail-sent-title{
    color: #F54562;
    font-size: 3rem;
    margin: auto;
}
.mail-sent-text{
    width: 70%;
    margin: auto;
}
.hr-mail-sent{
    width: 80%;
    margin: auto;
    color: black;
}
.loading-logo-bg{
    margin: auto;
    width: 30%;
    border-radius: 100%;
    background-color: #F54562;
    border: 0.2rem solid #ffffff22;
}
.loading-logo{
    width: 100%;
    padding: 20%;
}
@media screen and (max-width: 1050px){
    .text-success{
        font-size: 3rem;
    }
    .secondary-text-success{
        font-size: 1.2rem;
    }
    .background-signin{
        padding-top: 10%;
    }
}

@media screen and (max-width: 800px){
    .text-success{
        font-size: 2rem;
    }
    .secondary-text-success{
        font-size: 1rem;
    }
    .mail-sent-text{
        font-size: 0.8rem;
    }
    .text-checking{
        font-size: 1.5rem;
    }
    .background-signin{
        padding-top: 15%;
    }
    .logo-signin{
        padding-top: 1rem;
        width: 70%;
    }
}

@media screen and (max-width: 400px){
    .text-success{
        font-size: 1.7rem;
    }
    .secondary-text-success{
        font-size: 0.8rem;
    }
    .mail-sent-text{
        font-size: 0.6rem;
    }
    .icon-success{
        font-size: 10rem;
    }
    .text-checking{
        font-size: 1rem;
    }
    .background-signin{
        padding-top: 25%;
    }
    .input-spacing-login{
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0;
    }
    .logo-signin{
        width: 85%;
    }
    .title-create-account{
        margin-left: 30%;
        margin-right: 30%;
    }
}