dt{
    padding-bottom: 10px;
}
dd{
    padding-left: 20px;
}
#popover-positioned-top{
    font-size: 0.8rem;
    padding: 1rem;
    max-width: none !important;
    border: #afafaf 0.1rem solid;
}

.columns-input{
    display: flex;
}
.column-input{
    padding: 0 1.5rem 0 1.5rem;
}
.user-button{
    .dropdown{
        max-width: 100%;
    }
}

.column-input{
    .password-info{
        --bs-btn-bg: #ffffff !important;
        --bs-btn-border-color: #ffffff;
        --bs-btn-hover-bg: #ffffff;
        --bs-btn-focus-shadow-rgb: 247, 97, 122;
        --bs-btn-active-color: #ffffff;
        --bs-btn-active-bg: #ffffff;
        --bs-btn-active-border-color: #ffffff;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
        --bs-btn-disabled-color: #ffffff;
        --bs-btn-disabled-bg: #ffffff;
        --bs-btn-disabled-border-color: #ffffff;
    }
    .btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
        background-color: #ffffff !important;
        border-color: #ffffff !important;
        box-shadow: none;
    }
    .btn{
        padding: 0;
        border: none !important;
        --bs-btn-box-shadow: none !important;
    }
}

.password-info:active{
    background-color: #ffffff !important;
    border-color: #ffffff !important;
}
.password-info:active:focus{
    background-color: #ffffff !important;
    border-color: #ffffff !important;
}
.title-category-profile{
    text-align: center;
    color: white;
    background-color: #F54562;
    width: 10%;
    border-radius: 3rem;
    margin: auto;
    margin-right: 0
}
.hr-category-profile{
    color: #F54562;
    margin: auto;
    border-top: 2px solid;
    vertical-align: middle;
    width: 45%;
    margin-left: 0;
    margin-right: 0.5rem;
    opacity: none;
}
.icon-about-you{
    margin: auto;
    margin-left: 0;
    font-size: 3rem;
}

.profile-input-icons{
    color: #afafaf !important;
    font-size: 1.1rem;
}

.hiuser{
    border-radius: 1rem;
    margin-left: 10%;
    width: 80%;
    text-align: center;
    background-color: white;
    border: 0;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.last-login-span{
    background-color: var(--bs-border-color);
    color: #F54562;
    padding: 0.5rem 2rem;
    border-radius: 1rem;
}

.password-info{
    font-size: 1.2rem;
    color: #F54562 !important;
    padding-left: 0.3rem;
}
.password-required{
    border: #F54562 0.1rem solid;
}

.tooltip-inner{
    text-align: left !important;
    max-width: none !important;
    border: 0.1rem solid #afafaf;
    background-color: #ffffff !important;
    color: black !important;
}
@media screen and (max-width: 1300px){
    .title-category-profile{
        width: 20%;
    }
    .hr-category-profile{
        width: 35%;
    }
}
@media screen and (max-width: 800px){
    .userprofile span{
        font-size: 0.7rem;
    }
}
@media screen and (max-width: 700px){
    .title-category-profile{
        width: 25%;
    }
    .hr-category-profile{
        width: 30%;
    }
}
@media screen and (max-width: 500px){
    .hiuser{
        margin-left: 0;
        width: 100%;
    }
    .last-login-span{
        font-size: 0.8rem !important;
    }
    .title-category-profile{
        margin: auto;
        width: 60%;
    }
    .hr-category-profile{
        display: none;
    }
    .icon-about-you{
        display: none;
    }
}