
.solution-context ol,ul{
    padding-left: 12px;
}

.solution-context li{
    font-size: large;
    line-height: 1.4;
    list-style: none;
    padding-bottom: 0.3rem;
}

.solution-context .solution-context-key{
    color: #f3f7fa !important;
    padding: 0 0.3rem;
    font-weight: bold;
    background-color: #000000;
    border-radius: 0.3rem;
}

.solution-context .solution-context-value{
    padding: 0 0.3rem;
}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    @media print{
        // .benchmark-right{
        //     margin-top: 550px;
        //     height: 600px !important;
        // }
        // .benchmark-top-companies{
        //     margin-top: 675px;
        //     padding-top: 675px;
        //     background-color: transparent !important;
        // }
    }
}}
@media print{
    .target-chart .ct-series-a .ct-bar {
        stroke-width: 25px !important;
    }
    .industryCompCell{
        max-height: 875px !important;
    }
    .jobPostCell{
        max-height: 875px !important;
    }
    .card-body{
        width: 100% !important;
    }
    .widget-hidden{
        width: 100% !important
    }

    .accordion-item {
        max-height: 875px !important;
    }
    .benchmark{
        max-height: 875px !important;
    }

    .ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
        align-items: center;
        justify-content: flex-end !important;
        text-align: right !important;
        text-anchor: end;
        font-size: 12px !important;
    }
    .widget-hidden span.ct-label.ct-vertical.ct-start {
        margin-top: 0 !important;
        display: inline-block;
        margin-right: 0;
        text-overflow: ellipsis;
        white-space: inherit;
    }
    .series-value-chart .ct-label {
        font-size: 8px !important;
    }
}